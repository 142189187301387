.flipcard-back-face {
  position: absolute;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 20px 40px;
  backface-visibility: hidden;
  background-color: $primary;
  color: white;
  border-radius: 16px;
  transform: rotateY(180deg);
}

.flipcard-message {
  font-size: 16px !important;
  line-height: 24px;
  margin-top: 20px;
}

.flipcard-back-face-action {
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;
  font-size: 22px;
  line-height: 0;
  font-weight: 600;

  border-radius: 50%;

  background-color: $green;
  color: $primary;

  cursor: pointer;
  user-select: none;
}

.flipcard-back-face-action-label {
  position: relative;
  top: -1px;
}

.flipcard-back-face-action:hover {
  background-color: rgb(14, 163, 14);
}
