.sitemap {
  display: flex;
  justify-content: center;
  background-color: $primary;
  border-top: 0.5px solid $greyBackground;

  @media (min-width: 768px) {
    padding: 85px 40px;
  }

  .content-site-map {
    display: flex;
    font-family: Alliance2;
    background-color: $primary;
    max-width: 1440px;

    .menu-content {
      width: 100%;
      display: flex;
      flex-wrap: wrap;
      background-color: $primary;

      .section-title {
        color: $white;
        font-size: 0.875rem;
        font-weight: 500;
        list-style: none;
        display: flex;
        justify-content: flex-start;
        height: 24px;
        margin-bottom: 9px;
        text-transform: uppercase;
        width: 175px;

        &.privacyPolice {
          width: 275px;
        }
      }

      div {
        padding: 0;
        margin: 0;

        @media (min-width: 768px) {
          margin-right: 80px;
        }

        a {
          text-decoration: none;

          p {
            color: $greyFontColor;
            font-size: 1.125rem;
            font-weight: 500;
            list-style: none;
            height: 24px;
            margin-bottom: 9px;
            cursor: pointer;
            width: 100%;

            &.privacyPolice {
              width: 132%;

              @media (max-width: 768px) {
                width: 225px;
              }
            }

            &:after {
              display: block;
              content: '';
              width: 55%;
              border-bottom: solid 1px $white;
              transform: scaleX(0);
              transition: transform 250ms ease-in-out;
            }

            &:hover:after {
              transform: scaleX(1);
            }
          }
        }

        p {
          cursor: default;
          font-size: 1.125rem;
          font-weight: 500;
          letter-spacing: 0.019rem;
          color: $greyFontColor;
          text-decoration: none;
          margin: 0 0 9px 0;
          padding: 0;

          &:hover {
            color: $white;
          }
        }
      }

      .btn {
        display: none;

        @media (max-width: 768px) {
          margin-top: 30px;
          display: block;
          margin-left: 6%;
        }
      }

      .title-site-map {
        margin-bottom: 31px;

        .remote-offices {
          color: $greyFontColor;

          &:hover {
            color: $greyFontColor;
          }
        }
      }

      @media (max-width: 768px) {
        font-size: 0.7rem;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        margin-bottom: 9px;
        width: 100%;
      }
    }

    .icons {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      margin-top: 40px;
      margin-bottom: 40px;

      @media (min-width: 768px) {
        width: auto;
        display: flex;
        flex-direction: column;
        justify-content: start;
        margin-top: 0;
        margin-bottom: 0;
      }

      .seals {
        display: flex;

        @media (min-width: 768px) {
          justify-content: space-between;
        }
      }

      .seals > div {
        display: flex;
        height: auto;
        height: 73px;

        @media (min-width: 768px) {
          margin-top: 16px;
          height: 125px;
        }
      }

      .seals > div:nth-child(1) {
        margin-right: 16px;
      }

      &-content {
        display: flex;
        justify-content: space-between;

        .sitemap-icons {
          align-items: center;
          justify-content: center;
          display: flex;
          border-radius: 5px;
          height: 34px;
          background-color: $greyFontColor;
          width: 34px;

          position: relative;
          text-transform: uppercase;
          outline: 0;
          overflow: hidden;
          z-index: 1;
          transition: 0.08s ease-in;
          -o-transition: 0.08s ease-in;
          -ms-transition: 0.08s ease-in;
          -moz-transition: 0.08s ease-in;
          -webkit-transition: 0.08s ease-in;

          &.first {
            margin-right: 10px;
          }

          &:hover {
            &:before {
              top: 0;
            }

            .icon-svg {
              fill: $greyBackground;
            }
          }

          &:before {
            content: '';
            position: absolute;
            background: $white;
            bottom: 0;
            left: 0;
            right: 0;
            top: 100%;
            z-index: -1;
            transition: top 0.28s ease-in;
          }

          .icon-svg {
            fill: $white;
          }
        }
      }
    }

    .toggle-lang {
      width: 100%;
      display: flex;
      margin: 32px 0 48px 0;

      @media (min-width: 576px) {
        display: none;
        width: 90%;
      }
    }

    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 48px;
      padding: 0;
      width: 90%;
    }
  }
}
