.toggle-language {
  display: flex;
  justify-content: center;
  align-items: center;

  .switch-checkbox {
    height: 0;
    width: 0;
    position: absolute;
    visibility: hidden;

    &:checked + .switch-button {
      left: calc(100% - 2px);
      transform: translateX(-100%);
    }

    &:active + .switch-button {
      width: 60px;
    }
  }

  .switch {
    cursor: pointer;
    width: 78px;
    height: 39px;
    position: relative;
    background-color: $primary;
    transition: background-color 0.2s;

    @media (max-width: 576px) {
      width: 60px;
      height: 30px;
    }

    &.reverse {
      background-color: $white;
    }

    &.open {
      background-color: $white;
    }

    &.siteMapToggle {
      background-color: $white;
    }

    .switch-button {
      position: absolute;
      z-index: 1;
      top: 2px;
      left: 2px;
      width: 34px;
      height: 34px;
      transition: 0.2s;
      background-color: $green;

      @media (max-width: 576px) {
        width: 26px;
        height: 26px;
      }

      &.reverse {
        background-color: $default;
      }

      &.open {
        background-color: $greyFontColor;
      }

      &.siteMapToggle {
        background-color: $greyConcrete;
      }
    }
  }

  .switch-labels {
    display: relative;
    z-index: 0;
    height: 100%;

    span {
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 50%;
      height: 90%;
      font-family: 'Alliance2';
      font-size: 0.875rem;
      font-weight: 600;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      z-index: 1;

      &:first-child {
        left: 0;
        color: $green;

        &.portuguese {
          color: $default;
        }

        &.reverse {
          color: $default;

          &.portuguese {
            color: $white;
          }
        }

        &.siteMapToggle {
          color: $default;

          &.portuguese {
            color: $white;
          }
        }
      }

      &:last-child {
        right: 0;
        color: $green;

        &.english {
          color: $default;
        }

        &.reverse {
          color: $default;

          &.english {
            color: $white;
          }
        }

        &.siteMapToggle {
          color: $default;

          &.english {
            color: $white;
          }
        }
      }
    }
  }
}
