.input-container {
  display: flex;
  flex-direction: column;

  .input {
    border: 2.5px solid $primary;
    border-radius: 4px;
    height: 40px;
    padding: 0 10px;
    font-size: 16px;

    &.error {
      border: 2.5px solid $error;
    }
  }

  .input-radio {
    align-items: center;
    display: flex;
    justify-content: flex-start;
    height: 20px;

    @media (min-width: 768px) {
      height: 40px;
    }

    p {
      color: $primary;
      font-size: 1rem;
      font-weight: 400;
      margin: 0 8px;

      @media (min-width: 768px) {
        font-size: 1.5rem;
      }
    }
  }

  span.error {
    color: $error;
    font-size: 0.8rem;
    margin: 5px 3px 10px;
  }
}

input[type='radio'] {
  display: none;
}

.custom-radio {
  display: inline-block;
  margin: 0 !important;
  width: 20px;
  height: 20px;
  border: 2px solid #000;
  border-radius: 50%;
  position: relative;
  cursor: pointer;
}

.custom-radio::after {
  content: '';
  width: 12px;
  height: 12px;
  background-color: #000;
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 0;
  transition: opacity 0.2s;
}

input[type='radio']:checked + .custom-radio::after {
  opacity: 1;
}
