.service-section-container {
  width: 100vw;
  background-color: $primary;
}

.service-section-title-cards-footer {
  width: 100%;
}

.service-section-title-container {
  margin: 0 auto;
  padding: 100px 0 80px 0;
  width: 200px;
  height: 60px;
}

.service-section-card-wrapper {
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 1440px;

  @media screen and (max-width: 1600px) {
    margin: 0 auto;
    width: 90%;
  }

  @media screen and (max-width: 1080px) {
    max-width: 480px;
  }

  @media screen and (max-width: 600px) {
    justify-content: center;
    max-width: 420px;
  }
}

.service-section-title {
  margin-bottom: 4px;
  color: #fff;
  text-align: center;
  font-family: 'Alliance2';
  font-size: 40px;
  font-style: normal;
  font-weight: 500;
  text-transform: uppercase;
}

.service-section-title-underscore {
  height: 4px;
  border-radius: 10px;
  background: #02be3b;
}

.service-section-footer-sidescore {
  width: 4px;
  height: 60px;
  margin-right: 16px;
  border-radius: 10px;
  background: #02be3b;

  @media screen and (max-width: 900px) {
    width: 8px;
    height: 160px;
  }

  @media screen and (max-width: 680px) {
    height: 120px;
  }

  @media screen and (max-width: 480px) {
    height: 80px;
  }
}

.service-section-footer-title {
  position: relative;
  top: -8px;
  color: #fff;
  text-align: center;
  font-family: 'Alliance2';
  font-size: 80px;
  font-style: normal;
  font-weight: 600;
  letter-spacing: 2.4px;
  text-transform: lowercase;

  @media screen and (max-width: 900px) {
    text-align: start;
  }

  @media screen and (max-width: 680px) {
    font-size: 60px;
  }

  @media screen and (max-width: 480px) {
    font-size: 40px;
  }
}

.service-section-footer-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  padding: 160px 0;
  width: 880px;

  @media screen and (max-width: 900px) {
    width: 600px;
  }

  @media screen and (max-width: 680px) {
    width: 400px;
  }

  @media screen and (max-width: 480px) {
    width: 320px;
  }
}

.service-section-footer-title-wrapper {
  display: flex;
  align-items: center;
}

.service-section-footer-title-subtitle-wrapper {
  margin: 0 auto;
  margin-bottom: 48px;
}

.service-section-footer-subtitle {
  color: #969799;
  text-align: center;
  font-family: 'Alliance1';
  font-size: 32px;
  font-style: normal;
  font-weight: 400;
  text-transform: lowercase;

  @media screen and (max-width: 680px) {
    font-size: 18px;
  }

  @media screen and (max-width: 680px) {
    margin-left: 24px;
    text-align: start;
    font-size: 14px;
  }
}

.service-section-footer-button {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 180px;
  height: 40px;
  flex-shrink: 0;
  border-radius: 24px;
  border: 2px solid $green;
  background: $green;

  color: #171616;
  text-align: center;
  font-family: 'Alliance1';
  font-size: 14px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  text-transform: uppercase;
  cursor: pointer;
  -webkit-user-select: none;
  user-select: none;
}

.service-section-footer-button:hover {
  transition: 0.3s ease-in-out;
  background: $green2;
}

.testimony-section-container {
  width: 100vw;
  margin: 120px 0;
}

.testimony-section-header-videos {
  margin: 0 auto;
  max-width: 912px;
  width: 80%;
}

.testimony-section-header {
  margin: 48px 0;
  width: 300px;
}

.testimony-section-header-title {
  color: #000;
  -webkit-text-stroke-width: 1;
  -webkit-text-stroke-color: #000;
  font-family: 'Alliance2';
  font-size: 40px;
  font-style: normal;
  font-weight: 800;
  text-transform: uppercase;
}

.testimony-section-header-subtitle-wrapper {
  display: inline-block;
}

.testimony-section-header-subtitle {
  color: #000;
  font-family: 'Alliance2';
  font-size: 24px;
  font-style: normal;
  font-weight: 500;
  text-transform: lowercase;
}

.testimony-section-header-underscore {
  width: 100%;
  height: 4px;
  flex-shrink: 0;

  border-radius: 10px;
  background: #02be3b;
}

.testimony-section-videos-wrapper {
  display: flex;
  flex-direction: column;
}

.testimony-section-video {
  margin: 20px 0;
  height: 492px;

  @media screen and (max-width: 900px) {
    height: 360px;
  }

  @media screen and (max-width: 600px) {
    height: 272px;
  }

  @media screen and (max-width: 480px) {
    height: 172px;
  }
}
