.articles-view {
  .articles-content {
    width: 100%;
    padding-top: 88px;
    font-family: Alliance2;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .loader-container {
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .articles-container {
      width: 90%;
      max-width: 1440px;

      @media (min-width: 576px) {
        width: 90%;
      }

      a {
        color: inherit;
        text-decoration: none;
      }

      .articles-title {
        display: flex;
        flex-direction: column;
        margin-bottom: 88px;
        width: 100%;

        h1 {
          font-size: 3.5rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 0.54;
          letter-spacing: -0.069rem;
          color: $default;
          text-transform: uppercase;

          @media (max-width: 27rem) {
            font-size: 2.375rem;
          }
        }

        span {
          font-size: 1.375rem;
          font-weight: 600;
          line-height: 1.41;
          letter-spacing: 0.034rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          width: 40%;

          @media (max-width: 27rem) {
            font-size: 0.688rem;
            width: 70%;

            padding-top: 0;
          }
        }
      }

      .articles-item {
        width: 100%;
        margin-bottom: 50px;

        .articles-card {
          width: 100%;
          max-width: 425px;
          height: 278px;
          overflow: hidden;

          img {
            width: 100%;
            height: auto;
            background-position: center center;
            background-repeat: no-repeat;

            &:hover {
              transform: scale(1.1);
              transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
            }
          }
        }

        h3 {
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          font-size: 2.5rem;
          line-height: 1;
          letter-spacing: normal;
          margin: 26px 0 20px 0;
        }

        span {
          font-size: 1rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.38;
          letter-spacing: normal;
          color: $primary;
        }
      }
    }

    .see-more {
      display: flex;
      justify-content: center;
      padding: 100px 0 135px 0;

      @media (max-width: 576px) {
        padding: 20px 0 60px 0;
      }
    }

    p {
      font-size: 1.125rem;
      line-height: 21px;
      font-weight: bold;
      color: $default;
      margin: 0;
      display: flex;
      justify-content: center;
      padding: 100px 0 135px 0;

      @media (max-width: 576px) {
        padding: 20px 0 60px 0;
      }
    }

    @media (max-width: 576px) {
      padding-top: 60px;
    }
  }
}
