.contact {
  .contact-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Alliance2;

    &.open {
      display: none;
    }

    .contact-header-top {
      display: flex;
      flex-direction: column;
      width: 90%;
      margin-top: 40px;

      @media (min-width: 768px) {
        flex-direction: row;
        justify-content: space-between;
        margin-bottom: 55px;
        margin-top: 90px;
        max-width: 745px;
        width: 100%;
      }

      .header-content {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0;
        width: 90%;

        @media (min-width: 768px) {
          align-items: flex-end;
        }

        span {
          color: $primary;
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 18px;
          text-align: right;
          text-transform: uppercase;

          @media (min-width: 768px) {
            font-size: 1rem;
          }
        }
      }

      .header-title {
        align-items: flex-start;
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding: 20px 0;
        width: 90%;

        span {
          color: $primary;
          font-size: 0.875rem;
          font-weight: 500;
          margin-bottom: 10px;
          text-align: right;
          text-transform: uppercase;

          @media (min-width: 768px) {
            font-size: 1rem;
          }
        }

        h2 {
          color: $primary;
          font-size: 2rem;
          letter-spacing: -1.1px;
          line-height: 1.04;
          margin: 0;
          text-transform: uppercase;

          @media (min-width: 768px) {
            font-size: 3.5rem;
          }
        }

        h3 {
          color: $primary;
          font-size: 2rem;
          letter-spacing: -1.1px;
          line-height: 1.04;
          margin: 0 0 30px 0;

          @media (min-width: 768px) {
            font-size: 3.5rem;
          }
        }
      }
    }

    .contact-content {
      width: 90%;
      max-width: 745px;

      .info-title {
        margin-bottom: 16px !important;
      }

      .contact-content-infos {
        display: flex;
        flex-direction: column;
        margin-bottom: 40px;

        a {
          text-decoration: none;
          cursor: auto;
        }

        .info-item {
          display: flex;
          align-items: center;
          margin-bottom: 16px;
        }

        .info-label {
          font-size: 24px;
          margin-left: 12px;
        }

        .infos-title {
          color: $primary;
          font-family: 'Alliance2';
          font-size: 24px;
          font-weight: 900;
          margin-bottom: 18px;

          @media (min-width: 768px) {
            font-size: 1.5rem;
          }
        }

        .infos-subtitle {
          color: $greyFontColor;
          font-size: 24px;

          @media (min-width: 768px) {
            font-size: 1.5rem;
          }
        }

        span {
          color: $primary;
          font-size: 0.938rem;
          font-weight: 400;
          margin-bottom: 10px;
        }
      }

      .send {
        margin-bottom: 100px;
        max-width: 170px;
        width: 100%;
      }
    }
  }
}
