.articles-lg {
  background-color: $greyBackground;
  display: flex;
  justify-content: center;
  align-items: center;

  &-content {
    display: flex;
    flex-direction: column;
    width: 90%;
    max-width: 1440px;

    &-header {
      margin: 60px 0 40px 0;

      p {
        font-family: 'Alliance2';
        font-size: 1rem;
        font-weight: 600;
        color: $primary;
        margin: 0;
      }

      h3 {
        text-transform: uppercase;
        font-family: 'Alliance2';
        font-size: 3.5rem;
        font-weight: 400;
        line-height: 1.04;
        letter-spacing: -1.1px;
        color: $white;
        margin: 0;
        padding: 10px 0;
      }
    }

    .images-header {
      display: flex;

      .images {
        width: 100%;
        max-width: 670px;
        height: 670px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img {
          position: relative;
          width: 100%;
          height: auto;
        }

        .hover {
          transition: opacity 0.75s ease;
          opacity: 0;
        }

        &:hover .hover {
          opacity: 1;
          display: block;
        }

        @media screen and (max-width: 1100px) {
          height: 580px;
        }
        @media screen and (max-width: 900px) {
          height: 500px;
        }
      }
    }

    .images-content {
      display: flex;

      &-sm {
        width: 100%;
        max-width: 670px;
        height: 670px;
        overflow: hidden;
        position: relative;
        cursor: pointer;
        display: flex;

        .images-sm {
          width: 670px;
          display: flex;
          flex-direction: column;
          overflow: hidden;
          cursor: pointer;

          @media screen and (max-width: 1100px) {
            height: 580px;
          }
          @media screen and (max-width: 900px) {
            height: 500px;
          }

          &-space {
            width: 100%;
            height: 335px;
          }

          &-container {
            height: 335px;
            display: flex;
            overflow: hidden;

            &-imgs {
              width: 100%;
              height: 335px;
              position: relative;

              img {
                position: relative;
                width: 100%;
                height: auto;
              }

              .hover {
                transition: opacity 0.75s ease;
                opacity: 0;
              }

              &:hover .hover {
                opacity: 1;
                display: block;
              }

              .reverse {
                position: relative;
                width: 100%;
                height: auto;
              }
            }
          }
        }
      }

      .images {
        width: 100%;
        max-width: 670px;
        height: 670px;
        overflow: hidden;
        position: relative;
        cursor: pointer;

        img {
          width: 100%;
          height: auto;
          background-position: center center;
          background-repeat: no-repeat;
        }

        .hover {
          transition: opacity 0.75s ease;
          opacity: 0;
        }

        &:hover .hover {
          opacity: 1;
          display: block;
        }

        @media screen and (max-width: 1100px) {
          height: 580px;
        }
        @media screen and (max-width: 900px) {
          height: 500px;
        }
      }
    }
  }
}
