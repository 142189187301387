.layout {
  display: flex;
  flex-direction: column;
  height: 100%;

  // section[slot='content'] {
  //   max-width: 1250px;
  //   margin: auto;
  // }
}
