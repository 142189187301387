.not-found {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background-color: $primary;
  height: 93vh;

  &-svg {
    width: 100%;
    max-width: 90%;
    display: flex;
    justify-content: center;

    @media (min-width: 576px) {
      max-width: 594px;
    }

    svg {
      width: 100%;
      height: 116px;

      @media (min-width: 576px) {
        height: 216px;
      }
    }
  }

  p {
    font-family: 'Alliance1';
    font-size: 1rem;
    line-height: 28px;
    font-weight: 500;
    letter-spacing: 0.45px;
    color: $notFoundMessage;
    margin: 0;
    display: flex;
    justify-content: center;
    padding: 25px 0 63px 0;
    text-align: center;
  }

  &-button {
    width: 192px;
  }
}
