.button {
  background-color: inherit;
  border: solid 2.5px $primary;
  border-radius: 28px;
  color: $primary;
  cursor: pointer;
  font-size: 16px;
  font-stretch: normal;
  font-style: normal;
  font-weight: 600;
  letter-spacing: normal;
  line-height: normal;
  text-align: center;
  text-transform: uppercase;

  padding: 2px;
  height: 48px;
  width: 160px;

  position: relative;
  text-transform: uppercase;
  outline: 0;
  overflow: hidden;
  z-index: 1;
  transition: 0.08s ease-in;
  -o-transition: 0.08s ease-in;
  -ms-transition: 0.08s ease-in;
  -moz-transition: 0.08s ease-in;
  -webkit-transition: 0.08s ease-in;

  @media (max-width: 576px) {
    position: initial;
  }

  &:hover {
    color: $primary;
    border: 1px solid $primary;
    background-color: $primary;

    &:before {
      top: 0;
    }
  }

  &:before {
    content: '';
    position: absolute;
    background: $white;
    bottom: 0;
    left: 0;
    right: 0;
    top: 100%;
    z-index: -1;
    transition: top 0.28s ease-in;
  }

  &.outlinedSmall {
    width: 107px;
    height: 32px;
    font-size: 12px;
  }

  &.outlinedLarge {
    width: 100%;
    height: 56px;
    font-size: 18px;
    padding: 0;
  }

  &.solidMedium {
    width: 100%;
    color: $white;
    background-color: $primary;

    @media (max-width: 576px) {
      height: 48px;
    }

    &:hover {
      color: $primary;
      border: 1px solid $primary;

      &:before {
        top: 0;
      }
    }
  }

  &.notFound {
    width: 100%;
    color: $default;
    background-color: $green;
    border: 1px solid $green;

    @media (max-width: 576px) {
      height: 48px;
    }

    &:hover {
      color: $primary;
      border: 1px solid $primary;

      &:before {
        top: 0;
      }
    }
  }

  &.solidSmall {
    @extend .outlinedSmall;
    @extend .solidMedium;
  }

  &.solidLarge {
    @extend .outlinedLarge;
    @extend .solidMedium;
  }

  &.headerButton {
    color: $white;
    background-color: $primary;
    border: solid 2.5px $white;
    width: 200px;

    &.loading {
      padding-left: 40px;
    }

    &.inside {
      background-color: transparent;
    }

    &:hover {
      color: $primary;
      border: none;

      &:before {
        top: 0;
      }
    }
  }

  /* Sizes */
  &.medium {
    height: 48px;
    width: 192px;
    font-size: 16px;

    @media (max-width: 576px) {
      width: 105px;
      height: 32px;
      font-size: 12px;

      &.fullWidth {
        width: 100%;
        height: 40px;
      }
    }
  }

  &.large {
    height: 56px;
    width: 250px;
    font-size: 18px;

    @media (max-width: 576px) {
      width: 150px;
      height: 32px;
      font-size: 12px;

      &.fullWidth {
        width: 100%;
        height: 40px;
      }
    }
  }

  /* Types */
  &.rounded {
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 2rem;
    padding: 0;
    height: 40px;
    width: 40px;
  }
}
