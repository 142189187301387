.ceo-company-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-top: 0.4px solid $primary;
  background: $greyBackgroundOpacity;

  .ceo-company-content {
    margin-top: 55px;
    margin-bottom: 65px;
    width: 90%;
    max-width: 1440px;
    display: flex;
    justify-content: space-between;

    @media (min-width: 576px) {
      width: 90%;
    }

    .ceo-company {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
      max-width: 597px;

      .ceo-company-title {
        display: flex;
        height: 130px;
        width: 100%;

        &.reverse {
          text-align: end;
        }

        span {
          font-family: 'Alliance2';
          font-size: 3.25rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.08;
          letter-spacing: -2px;
          color: $primary;
          text-transform: uppercase;
          width: 160px;

          @media (max-width: 960px) {
            font-size: 1.375rem;
            line-height: 1.07;
            letter-spacing: -0.93px;
          }
        }

        @media (max-width: 960px) {
          height: 80px;
          align-items: flex-start;
          width: 120px;
        }
      }

      .ceo-company-subtitle {
        display: flex;
        height: 60px;
        width: 100%;

        &.reverse {
          text-align: end;
        }

        span {
          font-family: 'Alliance2';
          font-size: 1rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $primary;
          text-transform: uppercase;

          @media (max-width: 960px) {
            font-size: 0.625rem;
            line-height: 1.4;
          }
        }

        @media (max-width: 960px) {
          width: 40%;
          height: 0;
        }
      }

      .ceo-company-description {
        height: 137px;

        span {
          font-family: 'Alliance2';
          font-size: 1.375rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.45;
          letter-spacing: -0.63px;
          color: #171616;

          @media (max-width: 960px) {
            font-size: 0.813rem;
            line-height: 1.42;
            letter-spacing: normal;
          }
        }

        @media (max-width: 960px) {
          height: 140px;
          margin-bottom: 50px;
        }
      }

      .ceo-company-image {
        margin-top: 27px;
        margin-bottom: 45px;
        width: 100%;
        max-width: 220px;
        height: 220px;
        overflow: hidden;

        @media (max-width: 960px) {
          min-width: 160px;
          max-width: 160px;
          height: 160px;
          margin-top: 0;
          margin-bottom: 0;
        }

        img {
          width: 100%;
          height: auto;
          cursor: pointer;
          background-size: cover;
          background-position: center;
        }
      }
    }

    .ceo-company-header {
      display: flex;
      margin-bottom: 40px;
      width: 100%;

      &.reverse {
        flex-direction: row-reverse;
      }

      .ceo-company-header-user {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        margin: 0 22px;

        &.reverse {
          align-items: flex-end;
          margin-right: 22px;
        }
      }
    }

    @media (max-width: 960px) {
      flex-direction: column;
      margin-bottom: 0;
    }
  }
}
