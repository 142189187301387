.service-card-container {
  margin: 12px 0;
  width: 464px;
  height: 480px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
      129deg,
      rgba(137, 137, 137, 0.01) -10.86%,
      rgba(0, 0, 0, 0.15) 46.42%
    ),
    linear-gradient(
      213deg,
      rgba(221, 221, 221, 0.05) -39.72%,
      rgba(0, 0, 0, 0.15) 54.32%
    ),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  transition: 0.5s ease-in-out;

  @media screen and (max-width: 1600px) {
    min-width: 464px;
    width: calc(50% - 16px);
  }

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.service-card-container-active {
  border: 1px solid rgba(255, 255, 255, 0.4);
}

.service-card-container.pointer {
  cursor: pointer;
}

.service-card-header {
  display: flex;
  justify-content: space-between;
  padding: 32px 32px 40px 24px;

  @media screen and (max-width: 600px) {
    padding-bottom: 20px;
  }
}

.service-card-header-close {
  display: block;
  cursor: pointer;

  @media screen and (max-width: 600px) {
    display: none;
  }
}

.service-card-title-wrapper {
  display: inline-block;
}

.service-card-title {
  margin-bottom: 8px;
  color: rgba(255, 255, 255, 0.6);
  font-family: 'Alliance1';
  font-size: 24px;
  font-style: normal;
  font-weight: 300;
  line-height: 26px;
  transition: 0.5s ease-in-out;
}

.service-card-title.hover {
  color: $white;
  font-weight: 700;
}

.service-card-title.active {
  color: $white;
  font-size: 18px;
  font-weight: 700;
}

.service-card-title-underscore {
  width: 0;
  height: 1px;
  background-color: $white;
  transition: 0.3s ease-in-out;
}

.service-card-title-underscore.hover {
  width: 100%;
}

.service-card-title-underscore-active {
  width: 100%;
}

.service-card-img {
  fill-opacity: 0.2;
  stroke-opacity: 0.2;
  transition: 0.5s ease-in-out;
  transform-origin: center;

  animation: fadeIn 0.5s ease-in;
}

.service-card-img-active {
  fill-opacity: 0.6;
  stroke-opacity: 0.6;
  transform: rotate(5deg);
}

.service-card-active-container {
  margin: 0 24px;
  font-family: 'Alliance1';
  font-size: 16px;
  font-style: normal;
  font-weight: 300;
  line-height: 161%;

  animation: fadeIn 0.5s ease-in;
}

.service-card-active-text {
  height: 260px;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 8;
  overflow: hidden;
  text-overflow: ellipsis;

  color: $white;
  margin-bottom: 30px;
}

.service-card-active-img-action {
  display: flex;
  justify-content: space-between;
}

.service-card-active-action {
  display: flex;
  font-size: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  gap: 8px;
  flex-shrink: 0;
  color: $green !important;
  transition: 0.1s ease;
  cursor: pointer;
}

.service-card-active-action-label {
  text-decoration: none !important;
  text-decoration-line: none !important ;
}

.service-card-active-action.hover {
  color: $green2;
  font-weight: 600;
}

.service-card-active-action-underscore {
  width: 0;
  height: 1px;
  background-color: $green2;
  transition: 0.3s ease-in-out;
}

.service-card-active-action-underscore.hover {
  width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.service-card-mobile-container {
  display: none;
  margin: 12px 0;
  width: 90%;
  min-width: 230px;
  height: 264px;
  overflow: hidden;
  border-radius: 4px;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background: linear-gradient(
      129deg,
      rgba(137, 137, 137, 0.01) -10.86%,
      rgba(0, 0, 0, 0.15) 46.42%
    ),
    linear-gradient(
      213deg,
      rgba(221, 221, 221, 0.05) -39.72%,
      rgba(0, 0, 0, 0.15) 54.32%
    ),
    linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(0, 0, 0, 0.05) 100%);
  transition: 0.5s ease-in-out;
  border: 1px solid rgba(255, 255, 255, 0.4);

  @media screen and (max-width: 600px) {
    display: block;
  }
}

.service-card-mobile-container.active {
  height: 472px;
}

.service-card-mobile-header {
  display: flex;
  justify-content: space-between;
  padding: 32px 24px 20px 24px;
}

.service-card-mobile-title {
  margin-bottom: 8px;
  color: $white;
  font-family: 'Alliance1';
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 26px;
  transition: 0.5s ease-in-out;
}

.service-card-mobile-title-underscore {
  width: 100%;
  height: 1px;
  background-color: $white;
}

.service-card-mobile-header.active {
  padding-bottom: 40px;
}

.service-card-mobile-header-arrow {
  transition: 0.5s ease-in-out;
  cursor: pointer;
}

.service-card-mobile-header-arrow.active {
  transform: rotate(180deg);
}

.service-card-mobile-img {
  fill-opacity: 0.6;
  stroke-opacity: 0.6;
  transform-origin: center;
  transform: rotate(5deg);

  animation: fadeIn 0.5s ease-in;
}
