.contact-confirmation {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;

  &-content {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    max-width: 90%;
    width: 100%;

    h1 {
      color: $default;
      font-family: Alliance2;
      font-size: 1.813rem;
      font-weight: 600;
      line-height: 37px;
      margin: 0;
      text-align: center;
      padding: 68px 0 17px 0;

      @media (min-width: 576px) {
        font-size: 3rem;
        line-height: 58px;
      }
    }

    h6 {
      color: $default;
      font-family: Alliance2;
      font-size: 1.125rem;
      font-weight: 500;
      line-height: 21px;
      margin: 0;
      text-align: center;

      @media (min-width: 576px) {
        font-size: 1.313rem;
        line-height: 25px;
      }
    }

    p {
      color: $default;
      font-family: Alliance2;
      font-size: 0.875rem;
      font-weight: 400;
      line-height: 16px;
      margin: 0;
      text-align: center;
      padding: 80px 0 20px 0;

      @media (min-width: 576px) {
        font-size: 1.313rem;
        line-height: 25px;
      }
    }

    .our-articles {
      display: flex;
      justify-content: center;
      width: 100%;

      &-content {
        display: flex;
        width: 100%;

        @media (min-width: 576px) {
          max-width: 650px;
        }

        &-grid {
          margin-bottom: 50px;
          width: 100%;

          a {
            color: $default;
            text-decoration: none;

            .articles-card {
              height: 210px;
              overflow: hidden;
              width: 100%;

              @media (min-width: 576px) {
                width: 202px;
                height: 133px;
              }

              img {
                background-position: center center;
                background-repeat: no-repeat;
                height: auto;
                width: 100%;

                &:hover {
                  transform: scale(1.1);
                  transition: transform 1s cubic-bezier(0.25, 0.45, 0.45, 0.95);
                }
              }
            }

            h3 {
              font-size: 1.5rem;
              line-height: 1;
              margin: 26px 0 10px 0;
            }

            p {
              font-size: 1rem;
              font-weight: 500;
              line-height: 1.38;
              margin: 0;
              padding: 0;
              text-align: initial;
            }
          }
        }
      }
    }

    &-icons {
      display: flex;
      justify-content: center;
      width: 100%;

      &-content {
        display: flex;
        justify-content: space-between;
        max-width: 190px;
        padding: 30px 0 70px 0;
        width: 90%;

        @media (min-width: 576px) {
          max-width: 230px;
          padding: 30px 0 170px 0;
        }

        .sitemap-icons {
          align-items: center;
          justify-content: center;
          display: flex;
          height: 37px;
          width: 37px;

          .icon-svg {
            fill: $default;
            transition: transform 0.2s;

            &:hover {
              fill: $greyFontColor;
            }
          }
        }
      }
    }
  }
}
