.footer {
  display: flex;
  justify-content: center;
  background-color: $primary;
  border-top: 0.5px solid $greyBackground;

  .content-footer {
    display: flex;
    font-family: Alliance2;
    background-color: $primary;
    justify-content: space-between;
    width: 90%;
    max-width: 1440px;
    display: flex;
    align-items: center;
    height: 104px;

    @media (min-width: 576px) {
      width: 90%;
    }

    img {
      width: 100%;
      max-width: 112px;

      @media (min-width: 576px) {
        max-width: 200px;
      }
    }

    .wrapper {
      .toggle-lang {
        margin-left: 35px;

        @media (max-width: 576px) {
          display: none;
        }
      }
      span {
        font-size: 1.125rem;
        font-weight: 500;
        line-height: 1.67;
        color: $greyBackground;

        @media (max-width: 576px) {
          font-size: 0.75rem;
          text-transform: lowercase;
        }
      }

      @media (min-width: 576px) {
        display: flex;
        align-items: center;
      }
    }
  }
}
