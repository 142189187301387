.flipcard-container {
  position: relative;
  width: 320px;
  height: 520px;
  border-radius: 16px;

  transform-style: preserve-3d;
  transition: all 0.9s ease;

  @media (min-width: 768px) {
    width: 700px;
    height: 300px;
  }
}

.flipcard-container.rotate {
  transform: rotateY(180deg);
}
