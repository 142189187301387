.dropdown-selected {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-selected-label {
  margin-left: 8px;
  user-select: none;
}

.dropdown-selected-label.primary {
  color: $primary;
}

.dropdown-selected-label.secondary {
  color: $white;
}
