.dropdown-item {
  display: flex;
  align-items: center;
  font-size: 18px;
  font-weight: 600;
  cursor: pointer;
}

.dropdown-item-label {
  margin-left: 8px;
  user-select: none;
}
