.header-mobile-content {
  position: fixed;
  top: 0%;
  left: 0%;
  right: 0%;
  bottom: 0%;
  z-index: 100;

  .nav {
    display: flex;
    height: 20%;
    background-color: $greyFontColor;
    justify-content: space-between;

    .content {
      display: flex;
      margin-right: 1rem;
      height: 70px;
      align-items: center;

      .toggle {
        margin-right: 1rem;
        margin-top: 4%;
        color: $white;
        font-size: 25px;
        font-family: Alliance2;
      }

      .closed {
        margin-top: 5.8%;
      }
    }

    .logo {
      width: 150px;
      height: 80px;
    }
  }

  .header-list {
    left: 0;
    top: 15%;
    width: 100%;
    height: 100%;
    position: fixed;
    text-align: center;
    font-family: Alliance2;
    background-color: $greyFontColor;

    a {
      color: $grey;
      display: block;
      padding: 1rem;
      font-size: 1.625rem;
      transition: 0.3s;
      font-weight: 300;
      position: relative;
      text-decoration: none;
      background-color: $greyFontColor;
      text-transform: uppercase;

      &:hover {
        color: $white;
      }
    }
  }
}
