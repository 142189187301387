.case {
  .header-top {
    height: 679px;
    overflow: hidden;

    @media (max-width: 576px) {
      height: 200px;
    }

    &-cover {
      background-position: center 70%;
      background-repeat: no-repeat;
      background-size: cover;
      height: 100%;
      transition: all 0.35s linear;
    }
  }

  h2 {
    font-size: 2.8rem;
    margin: 55px 0 20px;
  }

  .all-cases {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Alliance2;
    padding: 75px 0;

    p {
      font-size: 1.1rem;
      font-weight: 400;
      line-height: 30px;
      margin-bottom: 25px;
    }

    .header {
      align-items: flex-start;
      background: transparent;
      display: inline-grid;
      grid-template-columns: minmax(200px, 15vw) auto;
      grid-template-rows: auto;
      max-width: 1440px;
      width: 90%;

      @media (min-width: 576px) {
        width: 90%;
        grid-column-gap: 200px;
      }

      &-back {
        display: flex;
        cursor: pointer;
        text-transform: uppercase;

        &-icon {
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          font-size: 2rem;
          padding: 0;
          height: 40px;
          width: 40px;
          border: 2px solid $primary;

          position: relative;
          text-transform: uppercase;
          outline: 0;
          overflow: hidden;
          z-index: 1;
          transition: 0.08s ease-in;
          -o-transition: 0.08s ease-in;
          -ms-transition: 0.08s ease-in;
          -moz-transition: 0.08s ease-in;
          -webkit-transition: 0.08s ease-in;

          &:hover {
            &:before {
              top: 0;
            }

            .icon-back {
              fill: $white;
            }
          }

          &:before {
            content: '';
            position: absolute;
            background: $primary;
            bottom: 0;
            left: 0;
            right: 0;
            top: 100%;
            z-index: -1;
            transition: top 0.28s ease-in;
          }
        }

        span {
          font-size: 1.1rem;
          font-weight: 600;
          margin-top: 7px;
          transition: transform 250ms ease-in-out;
          margin-left: 15px;

          &:after {
            display: block;
            content: '';
            border-bottom: solid 2px $primary;
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
          }
          &:hover:after {
            transform: scaleX(1);
          }
        }
      }

      &-title {
        padding-top: 10px;

        &-icons {
          display: none;
        }

        h1 {
          font-size: 3.5rem;
          margin-top: 5px;

          @media (max-width: 576px) {
            font-size: 3rem;
          }
        }

        h5 {
          margin: 0;
          text-transform: uppercase;
        }

        @media (max-width: 576px) {
          padding-top: 30px;
        }
      }

      &-content {
        display: flex;
        justify-content: center;

        p {
          font-weight: bold;
          margin-top: 20px;
          max-width: 250px;
        }
      }

      &-paragraph-first {
        align-self: flex-end;
        max-width: 65vw;

        p {
          margin-right: 10vw;

          @media (max-width: 576px) {
            margin-right: 0;
          }
        }

        h1 {
          width: 100%;
          max-width: 900px;
        }

        img {
          text-align: right;
          background-size: auto 100%;
          height: auto;
          margin: 60px 0;
          width: 100%;

          @media (max-width: 576px) {
            width: 90%;
          }
        }

        @media (max-width: 576px) {
          align-self: flex-start !important;
          max-width: 100% !important;
        }
      }

      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        width: 90%;
      }
    }

    .paragraph {
      align-self: flex-end;
      max-width: 65vw;

      p {
        margin-right: 10vw;

        @media (max-width: 576px) {
          margin-right: 0;
        }
      }

      @media (max-width: 576px) {
        align-self: center;
        max-width: 90%;
      }
    }

    .video-container {
      text-align: right;
      background: url('/assets/images/video.png') no-repeat center center;
      background-size: auto 100%;
      height: 480px;
      margin: 60px 0;
      width: 100%;

      @media (max-width: 576px) {
        width: 90%;
      }
    }

    .big-text {
      background-color: $default;
      color: $white;
      font-family: Alliance2;
      font-size: 1.8rem;
      margin: 65px 0;
      padding: 75px 8vw;
    }

    .images {
      align-self: stretch;
      display: inline-flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: center;
      min-height: 330px;
      margin: 60px 0;
      gap: 20px;

      img {
        max-height: 400px;
        max-width: 45vw;

        @media (max-width: 576px) {
          width: 100%;
          height: auto;
          max-height: auto;
          max-width: 100%;
        }
      }

      @media (max-width: 576px) {
        display: flex;
        flex-direction: column;
        align-self: initial;
        width: 90%;
      }
    }

    .horizontal-divider {
      align-self: flex-end;
      background-color: $primary;
      height: 9px;
      margin-right: 5vw;
      max-width: 60vw;
      width: 100%;
      margin-top: 90px;

      @media (max-width: 576px) {
        align-self: center;
        width: 100%;
        margin: 0;
        max-width: 90%;
        height: 6px;
        margin-top: 0;
      }
    }

    @media (max-width: 576px) {
      padding: 55px 0;
    }
  }

  .other-cases {
    margin-bottom: 100px;
  }
}
