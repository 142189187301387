.loader {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;

  div {
    box-sizing: border-box;
    display: block;
    position: absolute;
    margin: 4px;
    border: 4px solid $white;
    border-radius: 50%;
    animation: loader-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
    border-color: $white transparent transparent transparent;
  }

  div:nth-child(1) {
    animation-delay: -0.45s;
  }

  div:nth-child(2) {
    animation-delay: -0.3s;
  }

  div:nth-child(3) {
    animation-delay: -0.15s;
  }

  &.small {
    position: absolute;
    top: 12%;
    left: 10%;

    div {
      border-width: 3px;
      height: 30px;
      width: 30px;
    }
  }

  &.medium {
    height: 54px;
    width: 54px;
    div {
      border-width: 4px;
      height: 44px;
      width: 44px;
    }
  }

  &.large {
    height: 74px;
    width: 74px;
    div {
      border-width: 5px;
      height: 64px;
      width: 64px;
    }
  }
}

@keyframes loader-ring {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
