@font-face {
  font-family: 'Alliance1';
  font-weight: 200;
  font-style: 'normal';
  src: local('DegarismStudio-AllianceNo.1Light'),
    local('DegarismStudio-AllianceNo.1Light'),
    url('/assets/fonts/DegarismStudio-AllianceNo.1Light.woff') format('woff');
}
@font-face {
  font-family: 'Alliance2';
  font-weight: 200;
  font-style: 'normal';
  src: local('DegarismStudio-AllianceNo.2Light'),
    local('DegarismStudio-AllianceNo.2Light'),
    url('/assets/fonts/DegarismStudio-AllianceNo.2Light.woff') format('woff');
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  font-size: 16px;
  font-family: 'Alliance1', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

#root {
  height: 100%;
  display: flex;
  flex-direction: column;
}

@keyframes fade {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fade-up {
  0% {
    opacity: 0;
    transform: translateY(10%);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

.page {
  animation: fade 0.3s cubic-bezier(0.04, 0.5, 0.16, 0.96);
  height: 100%;
  display: flex;
  flex-direction: column;

  .component {
    animation: fade-up 0.3s cubic-bezier(0.04, 0.5, 0.16, 0.96);
    display: flex;
    flex: 1;
    flex-direction: column;
  }
}

.flex {
  display: flex;

  .space {
    flex: 1;
  }
}

.flex-column {
  flex-direction: column;
}

.slide-enter,
.slide-exit {
  transition: transform 1000ms ease-out;
}

.slide-enter {
  transform: translateX(100%);
}

.slide-enter.slide-enter-active {
  transform: translateX(0%);
}

.slide-exit {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateX(0%);
}

.slide-exit-active {
  transform: translateX(-100%);
}
