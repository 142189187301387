$default: #000000;
$primary: #171616;

$error: #f47171;
$greyBackground: #898989;
$greyBackgroundOpacity: rgba(137, 137, 137, 0.07);
$greyFontColor: #5f6061;
$greyConcrete: #4d4e4f;
$greyPosition: #cfcfcf;
$notFoundMessage: #b2b1b1;

$white: #ffffff;
$green: #02be3b;
$green2: #4eec7e;
$grey: #d8d8d8;

$headerHeight: 104px;
