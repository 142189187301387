.data {
  position: absolute;
  bottom: 0;
  height: 25%;
  width: 100%;
  background-color: $primary;
  color: $white;
  cursor: pointer;

  &.carouselHome {
    width: 100%;
    display: flex;
  }

  &-title {
    font-family: 'Alliance2';
    font-size: 1rem;
    font-weight: 300;
    line-height: 1;
    letter-spacing: -2.15px;
    text-transform: uppercase;

    &.articles {
      font-size: 2.5rem;
      margin: 30px 0 15px 40px;
    }

    &.articlesSmall {
      font-size: 1rem;
      letter-spacing: 0;
      margin: 15px 0 7px 20px;
    }

    &.carouselHome {
      font-size: 2.025rem;
      margin-top: 16px;
      margin-left: 24px;
    }
  }

  &-subtitle {
    font-family: 'Alliance2';
    font-size: 1.125rem;
    font-weight: 500;
    color: $greyBackground;
    text-transform: uppercase;
    margin: 0 0 0 40px;

    &.articlesSmall {
      font-size: 0.625rem;
      margin: 0 0 0 20px;
    }
  }

  @media (max-width: 576px) {
    display: none !important;
  }
}
