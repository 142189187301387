.ReactCollapse--collapse {
  transition: height 300ms;
}

.collapse {
  width: 100%;

  .collapse--header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    align-items: center;
    border-bottom: solid 2px $greyBackground;
    cursor: pointer;

    > p,
    svg {
      color: $greyBackground;
      font-weight: bold;
    }
  }

  .collapse--header--active {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-bottom: solid 2px $default;
    cursor: pointer;

    > p {
      color: $default;
      font-weight: bold;
    }
  }

  form {
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    margin-top: 20px;
  }

  label {
    display: flex;
    cursor: pointer;
    font-weight: 500;
    position: relative;
    overflow: hidden;
    margin-bottom: 0.375em;

    input {
      position: absolute;
      left: -9999px;

      &:checked + span {
        background-color: mix(#fff, $primary, 93%);

        &:before {
          box-shadow: inset 0 0 0 0.4375em $primary;
        }
      }
    }

    span {
      display: flex;
      align-items: center;
      padding: 0.375em 0.75em 0.375em 0.375em;
      border-radius: 99em;
      transition: 0.25s ease;

      &:hover {
        background-color: mix(#fff, $primary, 84%);
      }

      &:before {
        display: flex;
        flex-shrink: 0;
        content: '';
        background-color: #fff;
        width: 1.5em;
        height: 1.5em;
        border-radius: 50%;
        margin-right: 0.375em;
        transition: 0.25s ease;
        box-shadow: inset 0 0 0 0.125em $primary;
      }
    }
  }
}

.black {
  color: #000000 !important;
}
