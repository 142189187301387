.careers {
  .mt-5 {
    margin-top: 4rem !important;

    @media (max-width: 1366px) {
      margin-top: 1rem !important;
    }
  }

  .careers-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Alliance2;

    .careers-header-top {
      width: 90%;
      display: flex;
      flex-direction: column;
      margin-top: 40px;

      @media (min-width: 1366px) {
        flex-direction: row;
        justify-content: space-between;
        margin-top: 80px;
        margin-bottom: 55px;
        width: 90%;
        max-width: 1440px;
      }

      .header-content {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: center;
        align-items: flex-start;

        @media (min-width: 1366px) {
          align-items: flex-end;
        }

        span {
          text-transform: uppercase;
          font-size: 0.875rem;
          font-weight: 500;
          text-align: right;
          color: $primary;
          margin-bottom: 18px;

          @media (min-width: 1366px) {
            font-size: 1rem;
          }
        }
      }

      .header-title {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: center;
        align-items: flex-start;

        span {
          font-size: 0.875rem;
          font-weight: 500;
          text-align: right;
          color: $primary;
          margin-bottom: 10px;

          @media (min-width: 1366px) {
            font-size: 1rem;
          }
        }

        h2 {
          margin: 0;
          text-transform: uppercase;
          font-size: 2rem;
          line-height: 1.04;
          letter-spacing: -1.1px;
          color: $primary;

          @media (min-width: 1366px) {
            font-size: 3.5rem;
          }
        }
      }
    }

    .careers-description {
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      margin-bottom: 60px;

      @media (min-width: 1366px) {
        margin-bottom: 140px;
        width: 90%;
        max-width: 1440px;
      }

      .description {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: center;
        align-items: flex-start;
        margin-bottom: 10px;

        @media (min-width: 1366px) {
          margin-bottom: 0;
          width: 100%;
          max-width: 1300px;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: flex-start;
          padding: 0;
        }

        .images {
          display: flex;
          justify-content: center;
          align-items: flex-start;
          border: none;
          overflow: hidden;
          margin-top: 25px;

          @media (max-width: 576px) {
            border: none;
            display: block;
            margin-left: 0;
            margin-right: auto;
            width: 100%;
            margin-bottom: 0;
            margin-top: 0;
            height: auto;
          }

          .iframe {
            width: 586px;
            height: 506px;
            border: none;

            @media (max-width: 1366px) {
              width: 90vw;
            }

            @media (max-width: 576px) {
              width: 100%;
              height: 200px;
              border: none;
            }
          }

          &.greatPlace {
            width: 120px;
            height: auto;
            margin-top: 40px;

            @media (min-width: 1366px) {
              width: 235px;
              margin-top: 0;
            }
          }

          img {
            width: 100%;
            height: auto;
            background: no-repeat center center;

            @media (max-width: 576px) {
              height: 50%;
            }
          }
        }

        .recruitmentMenu {
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
          border: none;
          overflow: hidden;
          margin-top: 25px;
          width: 100%;
          max-width: 590px;

          @media (max-width: 1366px) {
            max-width: none;
          }

          @media (max-width: 576px) {
            border: none;
            display: block;
            margin-left: 0;
            margin-right: auto;
            width: 100%;
            margin-bottom: 0;
            margin-top: 0;
            height: auto;
          }
        }

        .description-content {
          width: 590px;

          @media (max-width: 1366px) {
            width: 100%;
          }

          &.reverse {
            max-width: 590px;
            display: flex;
            flex-direction: column;
            align-items: flex-end;
            margin-top: 160px;
          }

          h3 {
            font-family: 'Alliance1';
            font-size: 1.7rem;
            font-weight: 600;
            line-height: 1.41;
            letter-spacing: 0.55px;
            color: $default;
            margin: 10px 0;
            margin-bottom: 30px;

            @media (min-width: 1366px) {
              font-size: 2.813rem;
              width: 500px;

              &.reverse {
                width: 100%;
                display: table;
                text-align: end;
              }
            }
          }

          span {
            font-family: 'Alliance1';
            font-size: 1rem;
            font-weight: 400;
            line-height: 1.41;
            letter-spacing: 0.55px;
            color: $default;

            @media (min-width: 1366px) {
              font-size: 1.75rem;
              width: 500px;

              &.reverse {
                display: table;
                text-align: end;
                width: 600px;
              }
            }
          }
        }

        .images-content {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          @media (min-width: 1366px) {
            width: 650px;
            margin-top: 185px;
          }
        }
      }
    }

    .positions {
      background-color: $greyPosition;
      width: 100%;
      align-items: center;
      display: flex;
      flex-direction: column;
      font-family: Alliance2;

      .all-positions {
        width: 90%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-bottom: 60px;

        @media (min-width: 1366px) {
          margin-bottom: 120px;
        }

        .positions-top {
          width: 90%;
          max-width: 300px;
          display: flex;
          justify-content: center;

          @media (min-width: 1366px) {
            flex-direction: row;
            justify-content: space-between;
            margin-top: 90px;
            margin-bottom: 55px;
            width: 100%;
            max-width: 1380px;
          }

          .positions-top-title {
            width: 100%;
            display: flex;
            flex-direction: column;
            padding: 20px 0;
            justify-content: center;
            align-items: flex-start;

            h2 {
              margin: 0;
              text-transform: uppercase;
              font-size: 2rem;
              line-height: 1.04;
              letter-spacing: -1.1px;
              color: $primary;

              @media (min-width: 1366px) {
                font-size: 3.5rem;
              }
            }
          }
        }

        .positions-content {
          width: 90%;
          max-width: 300px;
          border: 1px solid $primary;
          margin-bottom: 20px;

          @media (min-width: 1366px) {
            width: 100%;
            max-width: 1380px;
            border: 2.5px solid $primary;
            margin-bottom: 32px;
          }

          .position-infos {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: $white;
            padding: 10px;

            @media (min-width: 1366px) {
              flex-direction: row;
              justify-content: space-between;
              padding: 15px 30px;
            }

            .position-title {
              display: flex;
              flex-direction: column;
              width: 100%;

              .position {
                font-size: 1rem;
                font-weight: 600;
                text-align: initial;
                line-height: 1.63;
                letter-spacing: -0.69px;
                color: $primary;

                @media (min-width: 1366px) {
                  font-size: 1.5rem;
                }
              }

              .location {
                text-transform: uppercase;
                font-size: 0.8rem;
                font-weight: 500;
                text-align: initial;
                line-height: 1.63;
                letter-spacing: -0.69px;
                color: $primary;
                margin-bottom: 10px;

                @media (min-width: 1366px) {
                  font-size: 1.125rem;
                }
              }
            }

            .see-more {
              width: 100%;

              @media (min-width: 576px) {
                width: 160px;
              }

              &-btn {
                width: 100%;
              }
            }
          }
        }
      }
    }
  }

  .card-vacancy {
    display: flex;
    flex-direction: row;
    background-color: #fff;
    border-bottom: solid 2px $greyBackground;
    // box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 2px 0px;
    padding: 2rem;
    // border-radius: 4px;

    .content {
      display: flex;
      flex-direction: column;

      span {
        font-size: 0.7rem !important;
        color: $greyBackground !important;
      }

      h3 {
        font-size: 1.5rem !important;
        margin-bottom: 0px !important;
      }

      .skills {
        margin-top: 14px;

        @media (max-width: 1366px) {
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
        }

        span {
          padding: 8px 15px;
          background-color: $greyBackground;
          color: #fff !important;
          border-radius: 30px;
          margin-right: 5px;
          font-size: 15px !important;

          @media (max-width: 1366px) {
            margin-bottom: 10px;
          }
        }
      }
    }

    .icon {
      padding: 2px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      svg {
        color: $greyBackground;
      }
    }
  }
}
