.home-variants-content {
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $green;

  .home-variants-container {
    background: $green;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    width: 90%;
    max-width: 1440px;

    @media (min-width: 576px) {
      width: 90%;
    }

    .home-variants-title {
      display: flex;

      @media (max-width: 576px) {
        margin-bottom: 40px;
        flex-direction: column;
      }

      h2 {
        color: $primary;
        font-family: Alliance1;
        font-size: 8.121rem;
        font-weight: 300;
        letter-spacing: -6.09889px;
        line-height: 119px;
        margin: 35px 0 0 0;
        text-align: center;
        text-transform: uppercase;
        cursor: default;

        @media (max-width: 1080px) {
          font-size: 6rem;
        }

        @media (max-width: 768px) {
          border: none;
          display: table;
          font-family: Alliance2;
          font-size: 37px;
          font-weight: 600;
          letter-spacing: -1.72547px;
          line-height: 40px;
          margin: 0;
          max-width: 320px;
          padding: 0;
          text-align: initial;
          width: 100%;
        }
      }
    }

    .home-variants-details {
      width: 100%;
      display: flex;
      justify-content: center;
      margin: 70px 0;

      @media (max-width: 576px) {
        margin: 0;
      }

      .home-variants-infos {
        display: flex;
        flex-direction: row;
        align-items: center;

        &.reverse {
          flex-direction: column-reverse;
          margin-bottom: 40px;
          width: 100%;
          align-items: flex-start;
        }

        a {
          width: 100%;

          @media (min-width: 576px) {
            width: auto;
          }
        }

        .home-variants-subtitle {
          width: 100%;
          max-width: 562px;
          margin-left: 65px;

          span {
            font-family: Alliance1;
            font-size: 1.125rem;
            font-weight: 600;
            font-stretch: normal;
            font-style: normal;
            line-height: 1.56px;
            letter-spacing: 0.45px;
            line-height: 1.56px;
            color: $primary;

            @media (max-width: 767px) {
              display: table;
              line-height: 20px;
              text-align: initial;
              font-size: 0.75rem;
              font-weight: 500;
              width: 100%;
            }
          }

          @media (max-width: 767px) {
            margin-left: 0;
            margin-bottom: 18px;
          }
        }

        @media (max-width: 767px) {
          flex-direction: column;
          align-items: center;
        }
      }
    }

    @media (max-width: 767px) {
      justify-content: space-evenly;
      margin-top: 24px;
    }
  }
}
