.privacy-policy {
  &-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 50px 0;

    &-content {
      width: 90%;
      max-width: 1440px;

      @media (min-width: 576px) {
        width: 90%;
      }

      h1 {
        font-size: 3.5rem;

        @media (max-width: 576px) {
          font-size: 2.5rem;
        }
      }
      h3 {
        font-size: 2rem;

        @media (max-width: 576px) {
          font-size: 1.5rem;
        }
      }

      span {
        font-weight: bold;
      }
    }
  }
}
