.header {
  position: relative;
  display: flex;
  background: $green;
  width: 100vw;
  justify-content: center;
  align-items: center;
  padding: 12px 0;

  &.absolute {
    padding: 0;
  }

  &.reverse {
    background-color: $primary;
  }

  &-container {
    width: 100%;
    max-width: 90%;

    @media (min-width: 576px) {
      max-width: 90%;
    }
  }

  .sidebar {
    transition: opacity 400ms ease-in-out;
    opacity: 0;

    &.closed {
      display: none;
    }

    &.open {
      width: 100%;
      opacity: 1;
      display: block;
    }

    @media (min-width: 64em) {
      width: 100%;
    }
  }
}
