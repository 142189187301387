.newsLetter-content {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: Alliance2;
  background-color: $primary;

  .newsLetter {
    .subtitle {
      font-size: 1rem;
      font-weight: 500;
      font-style: normal;
      line-height: normal;
      font-stretch: normal;
      letter-spacing: normal;
      color: $greyBackground;
      text-transform: uppercase;
    }
    .title {
      font-size: 3.5rem;
      line-height: 1.04;
      font-style: normal;
      font-weight: normal;
      font-stretch: normal;
      letter-spacing: -0.069rem;
      color: $white;
      text-transform: uppercase;
    }

    .text {
      font-size: 1.25rem;
      line-height: 1.5;
      font-weight: 600;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.031rem;
      color: $greyBackground;
      width: 90%;
    }
  }

  .form {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    max-width: 760px;

    input {
      width: 100%;
      height: 4.375rem;
      font-size: 1.375rem;
      font-weight: 600;
      line-height: 1.41;
      padding: 0 0 0 4%;
      font-style: normal;
      font-stretch: normal;
      letter-spacing: 0.034rem;
      color: $greyBackground;
      font-family: Alliance2;
      background-color: $primary;
      border: solid 0.156rem $greyBackground;

      @media (max-width: 576px) {
        width: 100%;
      }
    }

    .terms-submmit {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 90%;
      margin-bottom: 40px;

      @media (max-width: 576px) {
        width: 100%;
      }

      &-subscribe {
        position: relative;
      }
    }

    .terms {
      width: 68%;
      margin-right: 3%;
      font-size: 1rem;
      font-weight: 500;
      line-height: 1.94;
      font-style: normal;
      font-stretch: normal;
      font-family: Alliance2;
      letter-spacing: normal;
      color: $greyBackground;
      transition: 0.3s cubic-bezier(0.25, 0.45, 0.45, 0.95);

      a {
        @extend .terms;
        margin-right: 0;

        &:hover {
          color: $white;
        }
      }
    }

    .error {
      color: $error;
      font-family: Alliance2;
      font-size: 1rem;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: normal;
    }

    .success {
      color: $green;
      font-family: Alliance2;
      font-size: 1rem;
      font-weight: 600;
      line-height: 150%;
      letter-spacing: normal;
    }
  }

  @media (max-width: 48rem) {
    display: flex;
    flex-direction: column;

    .newsLetter {
      width: 100%;
      margin-top: 30px;
      margin-bottom: 15px;

      .text {
        display: none;
      }
    }

    .form {
      width: 100%;

      .terms {
        line-height: 1.33;
      }
    }
  }

  @media (max-width: 27rem) {
    .newsLetter {
      .subtitle {
        font-size: 0.625rem;
        line-height: 1.3;
      }

      .title {
        font-size: 1.875rem;
      }
    }

    .form {
      input {
        height: 2.625rem;
        font-size: 0.625rem;
        margin-bottom: 15px;
      }
      .terms {
        margin-right: 0;
        line-height: 1.33;
        font-style: normal;
        font-weight: normal;
        font-stretch: normal;
        letter-spacing: normal;
        width: 55%;
        font-size: 0.563rem;
        margin-right: -1.5%;
      }
    }

    .btn {
      height: 2rem;
      width: 6.7rem;
      font-size: 0.75rem;
    }
  }
}

.newsletter-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 90%;
  max-width: 1440px;
  margin: 85px 0;

  @media (max-width: 48rem) {
    flex-direction: column;
    width: 90%;
    margin: 0;
    padding: 0;
  }
}
