.content-ready-to-talk {
  background-color: $green;
  display: flex;
  justify-content: center;

  .readyToTalk {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 90%;
    max-width: 1440px;

    @media (min-width: 576px) {
      width: 90%;
    }

    .container {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
      justify-content: space-between;
      align-items: center;
      margin: 80px 20px 20px 20px;

      &.small {
        margin: 40px 0;
        flex-direction: revert;
        justify-content: space-between;
        align-items: center;

        @media (max-width: 576px) {
          flex-direction: column;
          justify-content: center;
          align-items: flex-start;
        }
      }

      .infos {
        display: flex;
        flex-direction: column;
        max-width: 1100px;
        margin-bottom: 50px;
        margin-right: 20px;

        &.small {
          margin-bottom: 0;

          @media (max-width: 576px) {
            margin-bottom: 35px;
          }
        }

        .get-in-touch {
          color: $white;
          font-size: 1rem;
          font-weight: 600;
          text-transform: uppercase;
          letter-spacing: normal;
          margin-bottom: 20px;

          @media (max-width: 576px) {
            font-size: 0.625rem;
            line-height: 24px;
            font-weight: 500;
            text-transform: uppercase;
            margin: 0 0 10px 0;
          }
        }

        &-title {
          color: $primary;
          font-weight: 300;
          line-height: 1.04;
          font-size: 48px;
          letter-spacing: -0.172rem;
          text-transform: uppercase;

          &.small {
            font-size: 3rem;

            @media (max-width: 576px) {
              font-size: 1.875rem;
              line-height: 0.98;
              font-weight: 400;
              letter-spacing: -0.87px;
              display: table;
              width: 100%;
            }

            @media (max-width: 432px) {
              max-width: 300px;
            }
          }

          @media (max-width: 576px) {
            font-size: 1.875rem;
            line-height: 0.98;
            font-weight: 400;
            letter-spacing: -0.87px;
            display: table;
            width: 100%;
          }

          @media (max-width: 432px) {
            max-width: 300px;
          }
        }

        @media (max-width: 576px) {
          margin-top: 0;
          margin-bottom: 35px;
          width: 100%;
        }
      }

      .ready-button {
        width: auto;

        @media (max-width: 576px) {
          width: 100%;
        }
      }

      @media (max-width: 576px) {
        padding: 40px 0;
        margin: 0;
        align-items: center;
      }
    }

    @media (max-width: 576px) {
      display: flex;
      justify-content: center;
    }
  }
}
