.dropdown {
  position: relative;
}

.container-dropdown {
  display: inline-block;
  position: absolute;
  width: 72px;
  z-index: 100;
  color: $white;
  background-color: $primary;
  border-radius: 8px;
  padding: 0 8px;
  box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
  user-select: none;
}
