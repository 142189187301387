.flipcard-front-face {
  position: absolute;
  overflow: hidden;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  padding: 40px;
  backface-visibility: hidden;
  background-color: $primary;
  color: $white;
  border-radius: 16px;

  @media (min-width: 768px) {
    display: flex;
    flex-direction: row;
    align-items: center;
  }
}

.flipcard-front-face:hover {
  transform: scale(1.005);
}

.flipcard-logo {
  position: relative;
  border-radius: 50%;

  background-color: $primary;

  @media (min-width: 768px) {
    display: flex;
    left: -40px;
    align-items: center;
  }
}

.flipcard-title {
  position: relative;
  font-size: 28px;
  top: 12px;
  font-weight: 700;
  text-transform: capitalize;

  @media (min-width: 768px) {
    position: absolute;
    display: flex;
    font-size: 44px;
    align-items: center;
    margin-left: 10px;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    color: $white;
  }
}

.flipcard-front-face-action {
  position: absolute;
  bottom: 40px;
  right: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  width: 36px;
  height: 36px;
  font-size: 22px;
  line-height: 0;
  font-weight: 600;

  border-radius: 50%;

  background-color: $green;
  color: $primary;

  cursor: pointer;
  user-select: none;
}

.flipcard-front-face-action-label {
  position: relative;
  top: -1px;
}

.flipcard-front-face-action:hover {
  background-color: rgb(14, 163, 14);
}
