.social {
  display: inline-flex;
  gap: 10px;

  &-buttons {
    align-items: center;
    justify-content: center;
    display: flex;
    border: 2px solid $primary;
    border-radius: 5px;
    height: 34px;
    background-color: $white;
    width: 34px;

    position: relative;
    text-transform: uppercase;
    outline: 0;
    overflow: hidden;
    z-index: 1;
    transition: 0.08s ease-in;
    -o-transition: 0.08s ease-in;
    -ms-transition: 0.08s ease-in;
    -moz-transition: 0.08s ease-in;
    -webkit-transition: 0.08s ease-in;

    &:hover {
      &:before {
        top: 0;
      }

      .svg {
        fill: $white;
      }
    }

    &:before {
      content: '';
      position: absolute;
      background: $primary;
      bottom: 0;
      left: 0;
      right: 0;
      top: 100%;
      z-index: -1;
      transition: top 0.28s ease-in;
    }

    .svg {
      fill: $primary;
    }
  }

  &.small a {
    height: 24px;
    width: 24px;
  }

  &.large a {
    height: 44px;
    width: 44px;
  }
}
