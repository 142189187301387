.carousel {
  width: 100%;
  &.casesHome {
    background-color: #fff;
    padding-top: 56px;
  }
  &.articlesHome {
    background-color: #898989;
    padding-top: 56px;
    @media (min-width: 576px) {
      padding-top: 0;
    }
  }
  &.articlesHomeLarge {
    background-color: #fff;
    padding-top: 56px;
  }
  .carousel-container {
    width: 90%;
    margin: 0 auto;
    max-width: 1440px;
    padding-bottom: 60px;
    @media (max-width: 576px) {
      padding-bottom: 50px;
    }
  }
  .react-multi-carousel-dot-list {
    align-items: baseline !important;
    .react-multi-carousel-custom-dot {
      display: inline-block;
      width: 33px;
      height: 3px;
      opacity: 1;
      padding: 5px 5px 5px 5px;
      box-shadow: none;
      transition: background 0.5s;
      border-style: none;
      padding: 0;
      margin: 0 8px;
      margin-right: 6px;
      outline: 0;
      cursor: pointer;
      background-color: #000;
      &.active {
        height: 7px;
      }
    }
  }
  .custom-arrow2 {
    position: absolute;
    bottom: auto;
    right: 4.3em;
    top: 7em;
    background-color: transparent;
    border: none;
    border-left: 4px solid #000;
    border-bottom: 4px solid #000;
    width: 67px;
    height: 67px;
    transform: rotate(225deg);
    cursor: pointer;
    &:focus {
      outline: none !important;
    }
    &:hover {
      border-left: 4px solid #02be3b;
      border-bottom: 4px solid #02be3b;
    }
    @media (max-width: 600px) {
      display: none;
    }
  }
  .slide-container {
    width: 95%;
    max-width: 416px;
    @media (max-width: 576px) {
      &.articlesHome {
        width: 95%;
      }
      &.articlesHomeLarge {
      }
    }
  }
  .images {
    background-color: #fff;
    width: 100%;
    height: 192px;
    @media (min-width: 576px) {
      max-width: 95%;
      height: 280px;
      position: relative;
      .hover-carousel {
        transition: opacity 0.75s ease;
        opacity: 0;
      }
      &:hover .hover-carousel {
        opacity: 1;
        display: block;
      }
    }
    &.articlesHome {
      height: 201px;
      img {
        height: 201px;
      }
    }
    &.articlesHomeLarge {
      height: 192px;
      overflow: hidden;
      @media (min-width: 576px) {
        max-width: 416px;
        height: 280px;
      }
      img {
        height: auto;
      }
    }
    img {
      width: 100%;
      height: 100%;
      background-position: center center;
      background-repeat: no-repeat;
      @media (min-width: 576px) {
        max-width: 416px;
        height: 280px;
      }
    }
  }
  a {
    color: inherit;
    text-decoration: none;
  }
  .infos {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h3 {
      font-family: Alliance2;
      color: #000000;
      line-height: 1;
      font-size: 2.5rem;
      letter-spacing: normal;
      font-style: normal;
      font-weight: normal;
      font-stretch: normal;
      margin: 24px 0 15px 0;
      text-align: initial;
      text-decoration: none;
      &.articlesHome {
        color: #fff;
        text-align: initial;
      }
      &.articlesHomeLarge {
        color: #000;
        text-align: initial;
      }
      @media (max-width: 600px) {
        font-size: 1.5rem;
      }
    }
    span {
      font-family: Alliance2;
      color: #000;
      line-height: 1;
      text-align: initial;
      font-size: 1rem;
      letter-spacing: normal;
      font-style: normal;
      font-weight: 500;
      font-stretch: normal;
      text-transform: uppercase;
      @media (max-width: 600px) {
        font-size: 0.625rem;
        margin-bottom: 10px;
      }
    }
    p {
      margin: 0;
      padding: 0;
      font-family: Alliance2;
      color: #898989;
      line-height: 12.4px;
      font-size: 0.625rem;
      font-weight: 500;
      text-transform: uppercase;
      margin-bottom: 30px;
      &.articlesHome {
        color: #a8a8a8;
        font-size: 0.875rem;
      }
      &.articlesHomeLarge {
        color: #898989;
        font-size: 0.625rem;
      }
      @media (min-width: 576px) {
        display: none;
      }
    }
  }
  .carousel-header {
    display: flex;
    width: 100%;
    justify-content: center;
    &-content {
      color: #000;
      font-family: 'Alliance2';
      font-weight: 300;
      text-transform: uppercase;
      margin: auto;
      display: flex;
      flex-direction: column;
      width: 100%;
      @media (max-width: 960px) {
        align-items: center;
        display: flex;
        flex-direction: column;
        margin-left: 0;
      }
      h5 {
        font-size: 1rem;
        font-weight: bold;
        margin: 0;
      }
      h3 {
        height: 80px;
        margin-top: 13px;
        margin-bottom: 44px;
        color: #000;
        font-size: 3.5rem;
        line-height: 1.04;
        letter-spacing: -1.1px;
        margin: 0 0 44px 0;
        &.articlesHome {
          color: #fff;
        }
        &.articlesHomeLarge {
          color: #000;
        }
        @media (max-width: 960px) {
          font-size: 1.87rem;
        }
        @media (max-width: 600px) {
          margin: 0;
        }
      }
    }
  }
  .content-button {
    display: none;
    @media screen and (max-width: 576px) {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-top: 30px;
      width: 100%;
      > div {
        width: 100%;
        .button {
          border: none;
          height: 40px;
          width: 100%;
          padding: 0;
          button {
            border: 2px solid #171616;
            color: #171616;
            &.articlesHome {
              border: 2px solid #fff;
              color: #fff;
            }
            &.articlesHomeLarge {
              border: 2px solid #171616;
              color: #171616;
            }
          }
        }
      }
    }
  }
  .carousel-content {
    position: relative;
    padding-bottom: 90px;
    @media (max-width: 600px) {
      padding-bottom: 20px;
    }
  }
}
