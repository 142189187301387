.menu {
  background-color: $green;
  position: relative;
  display: flex;
  width: 100%;

  .header-mobile {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    &.absolute {
      width: 90%;
    }

    .content {
      display: flex;

      @media (max-width: 321px) {
        margin-left: 15px;
      }
    }

    @media (min-width: 1281px) {
      display: none;
    }

    button {
      position: relative;
    }
  }

  .burguer-button {
    @media (min-width: 64em) {
      display: none;
    }
  }

  .burguer-button {
    margin-left: 25px;
    display: flex;
    flex-direction: column;
    justify-content: center;

    div {
      width: 2rem;
      height: 3px;
      background-color: $primary;

      &:nth-child(-n + 2) {
        margin-bottom: 0.3rem;
      }
    }
  }

  .logo-mobile {
    max-width: 112px;
    width: 100%;

    img {
      height: auto;
      width: 100%;
    }
  }

  .header-open-true {
    display: none;
  }

  .menu-content {
    display: flex;
    height: $headerHeight;
    justify-content: center;
    width: 100%;

    .header-open-false {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      font-family: Alliance2;
      list-style-type: none;
      width: 100%;
      max-width: 1440px;

      &.absolute {
        width: 90%;
      }

      .logo {
        cursor: pointer;
        max-width: 200px;
        width: 100%;

        img {
          height: auto;
          width: 100%;
        }
      }

      .menu-links {
        display: flex;
        justify-content: space-between;
        list-style-type: none;
        text-decoration: none;
        margin: 0;
        width: 800px;

        &.reverse {
          width: 940px;
        }
      }

      li {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-family: Alliance2;
        transition: transform 250ms ease-in-out;

        &:nth-child(-n + 4) {
          &:after {
            display: block;
            content: '';
            border-bottom: solid 3px $primary;
            transform: scaleX(0);
            transition: transform 250ms ease-in-out;
          }
          &:hover:after {
            transform: scaleX(1);
          }
        }

        &.reverse {
          &:nth-child(-n + 4) {
            &:after {
              display: block;
              content: '';
              border-bottom: solid 3px $white;
              transform: scaleX(0);
              transition: transform 250ms ease-in-out;
            }
            &:hover:after {
              transform: scaleX(1);
            }
          }
        }

        a {
          align-items: center;
          color: $default;
          display: flex;
          font-size: 22px;
          font-weight: 600;
          padding: 5px;
          height: 100%;
          text-decoration: none;

          &.reverse {
            color: white;
          }
        }

        &.dropdown {
          .dropdown-content {
            top: 100%;
          }
        }
      }

      .dropdown-content {
        min-width: 160px;
        position: absolute;
        text-transform: uppercase;
        background-color: $greyBackground;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        top: 80px !important;
        transition: opacity 0.5s ease;
        opacity: 0;

        a {
          display: block;
          font-size: 14px;
          font-weight: 600;
          color: $primary;
          padding: 12px 16px;
          text-align: center;

          &:hover {
            color: $white;
          }
        }
      }

      .dropdown:hover .dropdown-content {
        opacity: 1;
        display: block;
        z-index: 1;
      }

      .toggle {
        color: $white;
      }

      .button {
        padding-top: 1%;
      }
    }

    @media (max-width: 1280px) {
      display: none;
    }

    @media (max-width: 576px) {
      height: 56px;
      display: none;
    }
  }

  .header-opacity-true {
    @extend .header-open-false;
    opacity: 0.7;
  }

  .header-scroll-true {
    @extend .header-opacity-true;
    opacity: 0.7;
  }

  /* Reverse colors */

  &.reverse {
    background-color: $primary;

    .burguer-button div {
      background-color: $white;
    }
  }

  /* Absolute position */

  &.absolute {
    background-color: rgba($primary, 0.5);
    position: absolute;
    width: 100%;
    left: 0;
    display: flex;
    justify-content: center;

    @media (max-width: 576px) {
      height: 56px;
    }
  }
}
