.about {
  .about-header {
    align-items: center;
    display: flex;
    flex-direction: column;
    font-family: Alliance2;
    width: 100%;

    .about-header-top {
      width: 90%;
      display: flex;
      flex-direction: column-reverse;
      margin-top: 40px;

      .header-content {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: center;
        align-items: flex-start;

        span {
          text-transform: uppercase;
          font-size: 0.875rem;
          cursor: pointer;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: $primary;
          margin-bottom: 18px;

          @media (min-width: 1080px) {
            font-size: 1rem;
          }
        }

        @media (min-width: 1080px) {
          align-items: flex-end;
        }
      }

      .header-title {
        width: 90%;
        display: flex;
        flex-direction: column;
        padding: 20px 0;
        justify-content: center;
        align-items: flex-start;

        span {
          text-transform: uppercase;
          font-size: 0.875rem;
          font-weight: 500;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: right;
          color: $primary;
          margin-bottom: 10px;

          @media (min-width: 1080px) {
            font-size: 1rem;
          }
        }

        h2 {
          margin: 0;
          font-size: 2rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.04;
          letter-spacing: -1.1px;
          color: $primary;
          text-transform: uppercase;

          @media (min-width: 1080px) {
            font-size: 3.5rem;
          }
        }

        h3 {
          margin: 0;
          font-size: 2rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.04;
          letter-spacing: -1.1px;
          color: $primary;

          @media (min-width: 1080px) {
            font-size: 2.813rem;
            font-weight: 500;
          }
        }
      }

      @media (min-width: 1080px) {
        flex-direction: row-reverse;
        justify-content: space-between;
        margin-top: 90px;
        margin-bottom: 55px;
        width: 90%;
        max-width: 1440px;
      }
    }

    .header-description {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      span {
        font-family: 'Alliance1';
        font-size: 1rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 31px;
        letter-spacing: 0.55px;
        color: $default;

        @media (min-width: 1080px) {
          font-size: 1.375rem;
          width: 700px;
        }
      }

      @media (min-width: 1080px) {
        margin-bottom: 55px;
        width: 90%;
        max-width: 1440px;
      }
    }

    .header-content-title {
      width: 90%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: flex-start;

      h3 {
        margin: 30px 0;
        font-size: 2rem;
        font-weight: normal;
        font-stretch: normal;
        font-style: normal;
        line-height: 1.04;
        letter-spacing: -1.1px;
        color: $primary;

        @media (min-width: 1080px) {
          font-size: 2.813rem;
          font-weight: 500;
          margin-bottom: 55px;
        }
      }

      p {
        font-family: 'Alliance1';
        font-size: 1.375rem;
        font-weight: 600;
        font-stretch: normal;
        font-style: normal;
        line-height: 31px;
        letter-spacing: 0.55px;
        color: $default;
        margin: 0;
        max-width: 750px;
        width: 100%;

        @media (max-width: 1080px) {
          font-family: 'Alliance1';
          font-size: 1rem;
          font-weight: 600;
          font-stretch: normal;
          font-style: normal;
          line-height: 31px;
          letter-spacing: 0.55px;
          color: $default;
          margin: 0;
        }
      }

      @media (min-width: 1080px) {
        width: 90%;
        max-width: 1440px;
        margin-bottom: 55px;
      }
    }

    .about-content {
      background-color: $greyBackground;
      width: 100vw;
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 55px 0 50px 0;
      margin-top: 0;

      &-title {
        width: 90%;
        max-width: 1440px;

        @media (min-width: 1080px) {
          width: 90%;
        }

        h3 {
          margin: 0;
          font-size: 2rem;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: 1.04;
          letter-spacing: -1.1px;
          color: $primary;

          @media (min-width: 1080px) {
            font-size: 2.813rem;
            font-weight: 500;
          }
        }
      }

      &-top {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding-bottom: 20px;

        @media (min-width: 1080px) {
          width: 90%;
          max-width: 1440px;
        }

        .content-top {
          display: flex;
          flex-direction: column;

          .about-content {
            display: flex;
            flex-direction: column;
            align-items: flex-start;

            @media (max-width: 1080px) {
              align-items: center;
              margin-top: 0;
            }

            .images {
              display: flex;
              justify-content: center;
              align-items: flex-start;
              max-width: 408px;
              width: 100%;
              height: 395px;
              overflow: hidden;
              margin-bottom: 75px;

              img {
                width: 100%;
                height: auto;
                background: no-repeat center center;

                @media (max-width: 1080px) {
                  height: 50%;
                }
              }

              @media (max-width: 1080px) {
                align-items: flex-start;
                width: 90%;
                height: 100%;
                margin-bottom: 0;
              }
            }

            .infos {
              width: 90%;
              margin: 20px 0;

              span {
                text-transform: uppercase;
                font-family: 'Alliance2';
                font-size: 1rem;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $primary;
              }

              h2 {
                margin: 20px 0 30px 0;
                text-transform: uppercase;
                font-size: 3rem;
                font-weight: 300;
                font-stretch: normal;
                font-style: normal;
                line-height: 1.08;
                letter-spacing: -2px;
                color: $white;

                @media (min-width: 1080px) {
                  font-size: 3.25rem;
                  width: 400px;
                  margin-bottom: 47px;
                }
              }

              .infos-founder {
                text-transform: none;
                font-family: 'Alliance2';
                font-size: 1rem;
                font-weight: 500;
                font-stretch: normal;
                font-style: normal;
                line-height: normal;
                letter-spacing: normal;
                color: $primary;
                display: table;
                margin-top: 30px;

                @media (min-width: 1080px) {
                  display: table;
                  font-size: 1.375rem;
                  font-weight: 400;
                  width: 575px;
                  margin-top: 35px;
                }
              }

              @media (min-width: 1080px) {
                width: 100%;
              }
            }
          }

          @media (min-width: 1080px) {
            width: 100%;
            max-width: 1440px;
            flex-direction: row;
          }
        }
      }

      @media (max-width: 1080px) {
        margin-top: 35px;
        padding: 35px 0 25px 0;
      }
    }

    .icons {
      display: flex;

      .about-icons {
        align-items: center;
        justify-content: center;
        display: flex;
        border-radius: 5px;
        height: 34px;
        border: 2px solid $primary;
        width: 34px;

        position: relative;
        text-transform: uppercase;
        outline: 0;
        overflow: hidden;
        z-index: 1;
        transition: 0.08s ease-in;
        -o-transition: 0.08s ease-in;
        -ms-transition: 0.08s ease-in;
        -moz-transition: 0.08s ease-in;
        -webkit-transition: 0.08s ease-in;

        &.first {
          margin-right: 10px;
        }

        &:hover {
          border: 1px solid $primary;
          &:before {
            top: 0;
          }

          .icon-svg {
            fill: $white;
          }
        }

        &:before {
          content: '';
          position: absolute;
          background: $primary;
          bottom: 0;
          left: 0;
          right: 0;
          top: 100%;
          z-index: -1;
          transition: top 0.28s ease-in;
        }

        .icon-svg {
          fill: $primary;
        }
      }
    }
  }
}
