.our-services-container {
  width: 100%;
  display: flex;
  background: $primary;
}

.our-services-header-content-wrapper {
  width: 1000px;
  margin: 100px auto;

  @media screen and (max-width: 1080px) {
    width: 680px;
  }

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  @media screen and (max-width: 800px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 328px;
  }
}

.our-services-header {
  font-family: 'Alliance2';

  @media screen and (max-width: 960px) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}

.our-services-header-title {
  font-size: 24px;
  color: $white;
}

.our-services-header-title2 {
  font-size: 40px;
  color: $white;
}

.our-services-content {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
