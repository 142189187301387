.clients {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  background: $greyBackgroundOpacity;

  &-header {
    width: 90%;
    max-width: 1440px;
    margin-top: 48px;
    margin-bottom: 57px;

    @media (min-width: 576px) {
      width: 90%;
    }

    &-title {
      height: 80px;
      margin-top: 15px;

      h3 {
        color: $default;
        font-family: 'Alliance2';
        font-size: 3.5rem;
        font-weight: 300;
        line-height: 1.04;
        margin: 0;
        letter-spacing: -1.1px;
        text-transform: uppercase;

        @media (max-width: 960px) {
          font-size: 1.875rem;
          line-height: 0.97;
          letter-spacing: -0.59px;
          display: flex;
          justify-content: center;
        }
      }

      @media (max-width: 960px) {
        height: 40px;
      }
    }

    &-subtitle {
      display: flex;

      span {
        font-family: 'Alliance2';
        font-size: 1rem;
        font-weight: 500;
        color: $primary;
        text-transform: uppercase;
        width: 335px;

        @media (max-width: 960px) {
          font-size: 0.625rem;
          line-height: 1.4;
        }
      }

      @media (max-width: 960px) {
        text-align: center;
        width: 210px;
      }
    }

    @media (max-width: 960px) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }
  }

  &-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    max-width: 730px;
    width: 100%;

    > div {
      width: 100%;
      max-width: 90px;
      margin: 0 25px 50px 10px;

      @media (min-width: 960px) {
        max-width: 150px;
        margin: 0 25px 108px 10px;
      }

      .image {
        cursor: default;
        width: 100%;
        height: auto;
      }
    }

    @media (max-width: 960px) {
      width: 85%;
      overflow: hidden;
      margin-bottom: 20px;
    }
  }
}
